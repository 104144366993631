import {all, fork, put, takeEvery, call} from 'redux-saga/effects';
import {showGenericModal} from "../layout/actions";
import {AM_webServicePUT} from "../../scripts/api";
import {setUserLang} from "./actions";
import {reloadBrandData} from "../Brand/actions";

// Update member language preferences whenever language is changed
function* callLangsWebService({payload: langData}) {
        try {
            if (langData.reloadBrandData) {
                yield put(reloadBrandData())
            }
            const url = '/member/languagepreference';
            const response = yield call(AM_webServicePUT, url, {language: langData.lang});
            if (response.data) {
                yield put(setUserLang(langData.lang))
            }
        } catch (e) {
            yield put(showGenericModal())
        }

}

function* callLangChangeRequested({payload: langData}) {
    try {
        if (langData.reloadBrandData) {
            yield put(reloadBrandData())
        }
        yield put(setUserLang(langData.lang))

    } catch (e) {
        yield put(showGenericModal())
    }
}

function* watchCallLangsWebService() {
    yield takeEvery("@@langs/MEMBER_LANG_CHANGE_REQUESTED", callLangsWebService)
}

function* watchPublicLangChangeRequesteed() {
    yield takeEvery("@@langs/PUBLIC_LANG_CHANGE_REQUESTED", callLangChangeRequested)
}

export default function* langsSaga() {
    yield all([
        fork(watchCallLangsWebService),
        fork(watchPublicLangChangeRequesteed)
    ]);
}