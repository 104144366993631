const brandData = {
    alamocommutes: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: false,
        use_otp2: false,
        time_zone: 3,
        aboutUs: true,
    },
    baystatecommute: {
        //this is how to add phone number for contact page, if applicable
        // contact_phone: 8006857433,
        // contact_phone_display: '(800) 685-Ride',
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: true,
        use_otp2: false,
        time_zone: 1,
        trip_planner_mode_order: [1, 2, 6, 3, 4, 5],
        aboutUs: false
    },
    catma: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: true,
        use_otp2: false,
        time_zone: 1,
        aboutUs: true,
    },
    clubridelv: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: false,
        use_otp2: false,
        time_zone: 6,
        aboutUs: true,
    },
    commuteconnector: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: false,
        use_otp2: false,
        time_zone: 1,
        aboutUs: false,
    },
    commuteinfo: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: false,
        use_otp2: false,
        time_zone: 1,
        aboutUs: true,
    },
    commutepa: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: false,
        use_otp2: false,
        time_zone: 1,
        aboutUs: true,
    },
    commuterconnectmi: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: false,
        use_otp2: false,
        time_zone: 1,
        aboutUs: true,
    },
    commutesmart: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: false,
        use_otp2: false,
        time_zone: 3,
        aboutUs: true,
    },
    commutesmartnh: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: true,
        use_otp2: true,
        time_zone: 1,
        aboutUs: true,
    },
    connectingva: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: true,
        use_otp2: true,
        time_zone: 1,
        aboutUs: true,
    },
    crri: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: true,
        use_otp2: true,
        time_zone: 1,
        aboutUs: true,
    },
    ctrides: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: true,
        use_otp2: false,
        time_zone: 1,
        aboutUs: true
    },
    decs: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: false,
        use_otp2: false,
        time_zone: 1,
        aboutUs: true,
    },
    gomaine: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: true,
        use_otp2: false,
        time_zone: 1,
        aboutUs: true,
    },
    govermont: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: true,
        use_otp2: false,
        time_zone: 1,
        aboutUs: true,
        contact_phone: '8006857433',
        contact_phone_display: '(800) 685-RIDE',
    },
    mysmarttrips: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: false,
        use_otp2: false,
        time_zone: 1,
        aboutUs: true,
        contact_phone: '8652152916',
        contact_phone_display: '(865) 215-2916',
    },
    rethink: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: false,
        use_otp2: false,
        time_zone: 1,
        aboutUs: false,
    },
    riderollstroll: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: false,
        use_otp2: false,
        time_zone: 1,
        aboutUs: true,
    },
    sharearide: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: false,
        use_otp2: false,
        time_zone: 1,
        aboutUs: true,
    },
    staniscruise: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: true,
        use_otp2: true,
        time_zone: 6,
        aboutUs: true,
    },
    tryparkingit: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: false,
        use_otp2: false,
        time_zone: 3,
        aboutUs: false,
    },
    waytogokc: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: false,
        use_otp2: false,
        time_zone: 3,
        aboutUs: true,
    },
    wmrides: {
        currency: 'USD',
        distance: 1,
        volume: 1,
        weight: 1,
        country: 'US',
        use_open_trip_planner: false,
        use_otp2: false,
        time_zone: 1,
        aboutUs: true,
    }
}

export default brandData;