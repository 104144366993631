import React, {Suspense, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as api from '../scripts/api';
import {setBrandLangs, showGenericModal, updateBrand} from '../redux/actions';
import Loader from '../components/Loader';

// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import FIREBASE_CONFIG from "../firebase/config";
import brandData from "./brandData";

const AlamoCommutes = React.lazy(() => import('./alamocommutes'));
const BayStateCommute = React.lazy(() => import('./baystatecommute'));
const CATMA = React.lazy(() => import('./catma'));
const ClubRide = React.lazy(() => import('./clubridelv'));
const CommuteConnector = React.lazy(() => import('./commuteconnector'));
const CommuteInfo = React.lazy(() => import('./commuteinfo'));
const CommutePA = React.lazy(() => import('./commutepa'));
const CommuterConnectMI = React.lazy(() => import('./commuterconnectmi'));
const CommuteSmart = React.lazy(() => import('./commutesmart'));
const CommuteSmartNH = React.lazy(() => import('./commutesmartnh'));
const ConnectingVA = React.lazy(() => import('./connectingva'));
const CRRI = React.lazy(() => import('./crri'));
const CTrides = React.lazy(() => import('./ctrides'));
const DECS = React.lazy(() => import('./decs'));
const GoMaine = React.lazy(() => import('./gomaine'));
const GoVermont = React.lazy(() => import('./govermont'));
const MySmartTrips = React.lazy(() => import('./mysmarttrips'));
const ReThink = React.lazy(() => import('./rethink'));
const RideRollStroll = React.lazy(() => import('./riderollstroll'));
const ShareARide = React.lazy(() => import('./sharearide'));
const StanisCruise = React.lazy(() => import('./staniscruise'));
const TryParkingIt = React.lazy(() => import('./tryparkingit'));
const WayToGoKC = React.lazy(() => import('./waytogokc'));
const WMRides = React.lazy(() => import('./wmrides'));

const _FORCE_USE_FIREBASE = false;
const _DEV_PREFIX_DASH = "dev-";
const _QA_PREFIX_DASH = "qa-";
const _DEV_PREFIX_DOT = "dev.";
const _QA_PREFIX_DOT = "qa.";

const BrandLoadingOrError = (props) => {
    if (props.loading) {
        return <Loader/>;
    } else {
        return <div></div>
    }
}

function shouldUseFirebase() {
    if (_FORCE_USE_FIREBASE) return true;
    const domain = window.location.href;
    return !(domain.includes(_DEV_PREFIX_DASH) || domain.includes(_QA_PREFIX_DASH) || domain.includes(_DEV_PREFIX_DOT) || domain.includes(_QA_PREFIX_DOT));
}

const BrandSelector = ({children}) => {
    const brand = useSelector(state => state.Brand);
    const dispatch = useDispatch();

    //gets the brand domain for web service call (change dev prefixes to qa)
    const getBrandDomain = () => {
        let domain = window.location.hostname;
        const devPrefixes = [{
            qa: _QA_PREFIX_DASH,
            dev: _DEV_PREFIX_DASH
        },
            {
                qa: _QA_PREFIX_DOT,
                dev: _DEV_PREFIX_DOT
            }
        ]

        devPrefixes.forEach(prefix => {
            if (domain.includes(prefix.dev)) {
                domain = domain.replace(prefix.dev, prefix.qa);
            }
        });

        return domain;
    }

    //this was set manually in old web app basically as I'm doing below. Need the subdomain to find img/style directories, etc.
    //We should probably just add a field to operating region settings and have brand web service pass this so I don't have to set it manually
    const getBrandSubdomain = () => {
        const enteredDomain = getBrandDomain();
        const knownDomains = [
            "alamocommutes",
            "baystatecommute",
            "catma",
            "commuteconnector",
            "commuteinfo",
            "commutepa",
            "commuterconnectmi",
            "commutesmartnh",
            "connectingva",
            "crri",
            "ctrides",
            "decs",
            "gomaine",
            "govermont",
            "mysmarttrips",
            "rethink",
            "riderollstroll",
            "sharearide",
            "staniscruise",
            "tryparkingit",
            "waytogokc",
            "clubridelv",
            "commutesmart",
            "wmrides"
        ];
        const [foundDomain] = knownDomains.filter((knownDomain) => {
            return enteredDomain.includes(knownDomain)
        })
        return foundDomain;
    }

    const initializeFirebase = (subdomain) => {
        const firebaseConfig = FIREBASE_CONFIG[subdomain];
        const app = initializeApp(firebaseConfig);
        const analytics = getAnalytics(app);
    }

    useEffect(() => {

        if (brand.needToLoad) {
            const url = `/public/brand?domain=${getBrandDomain()}`;

            api.AM_webServiceGet(url).then(response => {
                const brandSubdomain = getBrandSubdomain();
                if (brandSubdomain) {

                    const newBrand = {
                        ...response.data,
                        subdomain: brandSubdomain,
                        ...brandData[brandSubdomain]
                    };

                    //initialize firebase
                    if (shouldUseFirebase()) {
                        initializeFirebase(newBrand.subdomain);
                    }

                    //update brand in redux
                    dispatch(updateBrand(newBrand));
                    dispatch(setBrandLangs(newBrand.languages))
                } else {
                    dispatch(updateBrand({loading: false}));
                    dispatch(showGenericModal());
                }
            }).catch(error => {
                dispatch(updateBrand({loading: false}));
                dispatch(showGenericModal());
            });
        }


    }, [brand.needToLoad]);


    return (
        <>
            {brand.subdomain ? (
                    <>
                        <Suspense fallback={<></>}>
                            {(brand.subdomain === 'alamocommutes') && <AlamoCommutes/>}
                            {(brand.subdomain === 'baystatecommute') && <BayStateCommute/>}
                            {(brand.subdomain === 'catma') && <CATMA/>}
                            {(brand.subdomain === 'clubridelv') && <ClubRide/>}
                            {(brand.subdomain === 'commuteconnector') && <CommuteConnector/>}
                            {(brand.subdomain === 'commuteinfo') && <CommuteInfo/>}
                            {(brand.subdomain === 'commutepa') && <CommutePA/>}
                            {(brand.subdomain === 'commuterconnectmi') && <CommuterConnectMI/>}
                            {(brand.subdomain === 'commutesmart') && <CommuteSmart/>}
                            {(brand.subdomain === 'commutesmartnh') && <CommuteSmartNH/>}
                            {(brand.subdomain === 'connectingva') && <ConnectingVA/>}
                            {(brand.subdomain === 'crri') && <CRRI/>}
                            {(brand.subdomain === 'ctrides') && <CTrides/>}
                            {(brand.subdomain === 'decs') && <DECS/>}
                            {(brand.subdomain === 'gomaine') && <GoMaine/>}
                            {(brand.subdomain === 'govermont') && <GoVermont/>}
                            {(brand.subdomain === 'mysmarttrips') && <MySmartTrips/>}
                            {(brand.subdomain === 'rethink') && <ReThink/>}
                            {(brand.subdomain === 'riderollstroll') && <RideRollStroll/>}
                            {(brand.subdomain === 'sharearide') && <ShareARide/>}
                            {(brand.subdomain === 'staniscruise') && <StanisCruise/>}
                            {(brand.subdomain === 'tryparkingit') && <TryParkingIt/>}
                            {(brand.subdomain === 'waytogokc') && <WayToGoKC/>}
                            {(brand.subdomain === 'wmrides') && <WMRides/>}
                        </Suspense>
                        {children}
                    </>)
                : (<BrandLoadingOrError loading={brand.loading}/>)}
        </>
    )
}

export default BrandSelector;