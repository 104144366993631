import React, {useEffect, useState} from 'react';
import { Route, Redirect } from 'react-router-dom';

import * as api from "../scripts/api";

import {useDispatch, useSelector} from "react-redux";

import {loginUser} from "../redux/actions";

import Loader from '../components/Loader';
import AccountVerifyModal from "../components/AccountVerifyModal";
import {AM_getURLParameter} from "../scripts/helpers";


/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, roles, path, name, ...rest }) => {

    const impersonate = Number(AM_getURLParameter("impersonate", -1)) === 1;
    const [showLoading, setShowLoading] = useState(false);
    const dispatch = useDispatch();
    const tryloginUser = (api.AM_isUserAuthenticated() === false && api.AM_getTokenIfNoAuthRequired()) || impersonate;
    const {app_name} = useSelector((state) => state.Brand);
    const user = useSelector((state) => (state.Auth.user));
    const userDeleted = useSelector((state) => (state.Auth.userDeleted));
    const loading = useSelector((state) => (state.Auth.loading));



    useEffect(() => {
        if (tryloginUser && !showLoading && !loading) {
            setShowLoading(true);
            if (impersonate) {
                const username = AM_getURLParameter("u", "");
                const password = AM_getURLParameter("p", "");
                dispatch(loginUser(username, password, false, null));
            } else {
                dispatch(loginUser(null, null, true, api.AM_getTokenFromStorage()));
            }
        }

        if (!tryloginUser && showLoading) setShowLoading(false);
    });

    useEffect(() => {
        document.title = `${app_name} - ${name}`;
    }, []);




    return (
        <Route
            {...rest}
            render={(props) => {

                if (showLoading || tryloginUser) {
                    return <Loader />;
                }
                if (api.AM_isUserAuthenticated() === false && !tryloginUser) {

                    const prev = props.location.state;
                    const next = (prev && prev.from && prev.from.pathname ? prev.from.pathname : userDeleted ? "/account/deleted" : '/account/login');
                    // not logged in so redirect to login page or previous page if it was past in (landing page)
                    return <Redirect to={{pathname: next, state: {from: props.location}}}/>;
                }

                const loggedInUser = api.AM_getLoggedInUser();

                    // check if route is restricted by role
                if (roles && roles.indexOf(loggedInUser.role) === -1) {
                    // role not authorised so redirect to home page
                    return <Redirect to={{pathname: '/'}}/>;
                }

                if (loggedInUser && loggedInUser.verification_required) {
                    return <Redirect to={{pathname: '/account/verify'}}/>
                }
                    // authorised so return component
                return <><Component {...props} />{user && <AccountVerifyModal />}</>;

            }}
        />
    );
};

export default PrivateRoute;
