// @flow
import {AuthActionTypes} from './constants';

type AuthAction = { type: string, payload: {} | string };

// common success
export const authApiResponseSuccess = (actionType: string, data: any): AuthAction => ({
    type: AuthActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): AuthAction => ({
    type: AuthActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const loginUser = (email: string, password: string, remember: boolean, token: string): AuthAction => ({
    type: AuthActionTypes.LOGIN_USER,
    payload: { email, password, remember, token },
});

export const logoutUser = (): AuthAction => ({
    type: AuthActionTypes.LOGOUT_USER,
    payload: {},
});

export const signupUser = (firstname: string, lastname: string, email: string, password: string, region_id: number, partner_org_id: number, partner_org_affiliation_id): AuthAction => ({
    type: AuthActionTypes.SIGNUP_USER,
    payload: { firstname, lastname, email, password, region_id, partner_org_id, partner_org_affiliation_id },
});

export const deleteUser = (): AuthAction => ({
    type: AuthActionTypes.DELETE_USER,
    payload: {},
});


export const deleteUserComplete = (): AuthAction => ({
    type: AuthActionTypes.DELETE_USER_COMPLETE,
    payload: {},
});

export const forgotPassword = (email: string): AuthAction => ({
    type: AuthActionTypes.FORGOT_PASSWORD,
    payload: { email },
});

export const forgotPasswordChange = (email: string): AuthAction => ({
    type: AuthActionTypes.FORGOT_PASSWORD_CHANGE,
    payload: { email },
});

export const updatePhoto = (photo: Object): AuthAction => ({
    type: AuthActionTypes.UPDATE_PHOTO,
    payload: { photo },
});

export const updatePassword = (password, token): AuthAction => ({
    type: AuthActionTypes.UPDATE_PASSWORD,
    payload: { password, token },
});

export const updateHome = (location): AuthAction => ({
    type: AuthActionTypes.UPDATE_HOME,
    payload: { location },
});

export const updateWork = (location): AuthAction => ({
    type: AuthActionTypes.UPDATE_WORK,
    payload: { location },
});

export const updateLocations = (locations): AuthAction => ({
    type: AuthActionTypes.UPDATE_LOCATIONS,
    payload: { locations },
});

export const updateEmails = (emails): AuthAction => ({
    type: AuthActionTypes.UPDATE_EMAILS,
    payload: { emails },
});

export const updateProfile = (profileInfo, needsVerification): AuthAction => ({
    type: AuthActionTypes.UPDATE_PROFILE,
    payload: { profileInfo, needsVerification },
});

export const updateEmailVerify = (isVerified): AuthAction => ({
    type: AuthActionTypes.UPDATE_EMAIL_VERIFY,
    payload: { isVerified },
});

export const resetAuth = (): AuthAction => ({
    type: AuthActionTypes.RESET,
    payload: {},
});
